import { computed, reactive } from 'vue';
import { LOG_TYPE, logger } from '@emobg/web-utils';

export const usePagination = () => {
  const currentPage = reactive({
    activeBookings: 1,
    historyBookings: 1,
  });

  const currentPageType = computed(() => {
    const result = {};
    Object.keys(currentPage).forEach(i => {
      result[i] = i;
    });
    return result;
  });

  const getActiveBookingPage = () => currentPage.activeBookings;

  const getHistoryBookingPage = () => currentPage.historyBookings;

  const setActiveBookingPage = (num = 1) => {
    currentPage.activeBookings = num;
  };

  const setHistoryBookingPage = (num = 1) => {
    currentPage.historyBookings = num;
  };

  const loadActiveBookings = () => {
    logger.message(
      'You are using My Booking pagination composable. Method "loadActiveBookings" should be overridden',
      LOG_TYPE.warning,
    );
  };

  const loadHistoryBookings = () => {
    throw new Error(
      'You are using My Booking pagination composable. Method "loadHistoryBookings" should be overridden',
    );
  };

  const goToPage = (pageType, num) => {
    currentPage[pageType] = num;
    const actives = currentPageType.value.activeBookings === pageType;
    const history = currentPageType.value.historyBookings === pageType;

    if (actives) {
      loadActiveBookings();
    } else if (history) {
      loadHistoryBookings();
    }
  };

  const addPage = (pageType, num = 1) => {
    currentPage[pageType] += num;
    const actives = currentPageType.value.activeBookings === pageType;
    const history = currentPageType.value.historyBookings === pageType;

    if (actives) {
      loadActiveBookings();
    } else if (history) {
      loadHistoryBookings();
    }
  };

  const subtractPage = (pageType, num = 1) => {
    currentPage[pageType] -= num;
    const actives = currentPageType.value.activeBookings === pageType;
    const history = currentPageType.value.historyBookings === pageType;

    if (actives) {
      loadActiveBookings();
    } else if (history) {
      loadHistoryBookings();
    }
  };

  const getShowPaginationText = ({
    itemsPerPage,
    currentOpenedPage,
    items,
    totalItems,
  }) => ({
    from: itemsPerPage * (currentOpenedPage - 1),
    to: itemsPerPage * (currentOpenedPage - 1) + items,
    total: totalItems,
  });

  return {
    currentPage,
    currentPageType,
    getActiveBookingPage,
    getHistoryBookingPage,
    setActiveBookingPage,
    setHistoryBookingPage,
    loadActiveBookings,
    loadHistoryBookings,
    goToPage,
    addPage,
    subtractPage,
    getShowPaginationText,
  };
};
